<template>
<div id="page" class="crc-ma-in-character">
  <el-row class="cmic-form-row row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="txt_keywords">
        <el-input v-model="form.txt_keywords" placeholder="CRC姓名">
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" class="cmicfr-add-btn" @click="handleAdd">添加CRC</el-button>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txt_crc_code" label="编号" align="center">
      <template #default="scope">{{ scope.row.txt_crc_code||"- -" }}</template>
    </el-table-column>
    <el-table-column prop="txt_crc_name" label="姓名" align="center" />
    <el-table-column prop="txt_login_name" label="登录名" align="center" />
    <el-table-column prop="is_disabled" label="用户状态" align="center">
      <!-- 1启用 0禁用 -->
      <template #default="scope">
        <template v-if="scope.row.is_disabled==1">已启用</template>
        <template v-else-if="scope.row.is_disabled==0">已禁用</template>
        <template v-else>- -</template>
      </template>
    </el-table-column>
    <el-table-column prop="dt_create" label="创建时间" align="center">
      <template #default="scope">{{ scope.row.dt_create||"- -" }}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" @click="handleEdit(scope.row)">编辑</div>
        <div class="table-icon" @click="handleReset(scope.row)">重置密码</div>
        <div class="table-icon" v-if="scope.row.is_disabled==null" @click="handleDelete(scope.row)">删除</div>
        <div class="table-icon" v-if="scope.row.is_disabled==null||scope.row.is_disabled==0" @click="handleUpdateAuth(scope.row, 1)">启用</div>
        <div class="table-icon" v-if="scope.row.is_disabled==1" @click="handleUpdateAuth(scope.row, 0)">禁用</div>
      </template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <update-dialog v-if="dialog.show" :data="dialog.data" @close="(e) => {dialog.show=false;if(e==true){getList()}}" />
</div>
</template>

<script>
// CRC - 用户角色管理
import { Pagination, UpdateDialog } from "components";
import { ElMessage, ElMessageBox } from 'element-plus';
import { reactive, toRefs, onMounted, ref } from 'vue';
import { getCRCList, addCRC, editCRC, updateCRCAuth, resetCRCPsd, deleteCRC } from "api/apis.js";
export default {
  components: {
    Pagination,
    UpdateDialog
  },
  setup() {
    const pageRef = ref(null);
    const state = reactive({
      form: {
        txt_keywords: null,
        dateRange: [],
        page: 1,
        limit: 10
      },
      table: {
        data: [],
        loading: false,
        total: 0
      },
      dialog: {
        show: false,
        data: {}
      }
    });

    onMounted(() => {
      getList();
    })

    // 删除
    const handleDelete = row => {
      ElMessageBox.confirm(`删除后不可恢复，请确认是否删除用户${row.txt_crc_name}？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消"
      }).then(() => {
        state.table.loading = true;
        deleteCRC({ t_user_id: row.t_crc_id }).then(response => {
          if (response.code == 200) {
            ElMessage.success("删除成功");
            getList();
          } else {
            ElMessage.error("删除失败：" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      })
    };

    // 重置密码
    const handleReset = row => {
      ElMessageBox.confirm(`确认是否重置当前用户【${row.txt_crc_name}】密码为初始密码？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消"
      }).then(() => {
        state.table.loading = true;
        resetCRCPsd({ t_user_id: row.t_crc_id }).then(response => {
          if (response.code == 200) {
            ElMessage.success("重置成功");
            getList();
          } else {
            ElMessage.error("重置失败：" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      })
    };

    // 启用-1/禁用-0 用户
    const handleUpdateAuth = (row, type) => {
      let enableHint = `确认是否【启用】当前用户【${row.txt_crc_name}】？`,
        forbiddenHint = `禁用状态下该用户不可访问系统，请确认是否禁用【${row.txt_crc_name}】？`;
      ElMessageBox.confirm(type == 1 ? enableHint : forbiddenHint, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消"
      }).then(() => {
        state.table.loading = true;
        updateCRCAuth({
          t_user_id: row.t_crc_id,
          status: type
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            getList();
          } else {
            ElMessage.error("操作失败，" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      })
    };

    // 添加用户
    const handleAdd = () => {
      state.dialog.data = {
        title: "添加用户",
        type: "add",
        data: [
          { key: "txt_login_name", message: "登录名不能为空", type: "input", hint: false, label: "登录名", value: null },
          { key: "txt_crc_code", message: "用户编码不能为空", type: "input", hint: false, label: "用户编码", value: null },
          { key: "txt_crc_name", message: "用户姓名不能为空", type: "input", hint: false, label: "用户姓名", value: null },
          // 等保
          // { key: "txt_mobile", message: "请填写用户联系方式，用于登录", type: "input", hint: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, label: "联系方式", value: null }
        ],
        api: addCRC
      };
      state.dialog.show = true;
    };

    // 编辑CRC
    const handleEdit = row => {
      state.dialog.data = {
        title: "编辑用户",
        type: "edit",
        data: [
          { key: "txt_login_name", message: "登录名不能为空", type: "input", hint: false, label: "登录名", value: row.txt_login_name },
          { key: "txt_crc_code", message: "用户编码不能为空", type: "input", hint: false, label: "用户编码", value: row.txt_crc_code },
          { key: "txt_crc_name", message: "用户姓名不能为空", type: "input", hint: false, label: "用户姓名", value: row.txt_crc_name },
          // 等保
          // { key: "txt_mobile", message: "请填写用户联系方式，用于登录", type: "input", hint: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, label: "联系方式", value: row.txt_mobile }
        ],
        t_crc_id: row.t_crc_id,
        api: editCRC
      };
      state.dialog.show = true;
    };

    // 获取列表数据
    const getList = () => {
      state.table.loading = true;
      let param = {
        txt_keywords: state.form.txt_keywords,
        dt_from: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[0],
        dt_end: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[1],
        pages: state.form.page,
        pageSize: state.form.limit
      }

      // 去掉空数据
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if ((param[keys[i]] || "") == "") {
          delete param[keys[i]];
        }
      }

      getCRCList(param).then(response => {
        if (response.code == 200) {
          state.table.data = response.data;
          state.table.total = response.total;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 重置表单，重新搜索
    const reset = () => {
      state.form = {
        txt_keywords: null,
        dateRange: [],
        page: 1,
        limit: 10
      };
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getList();
    }

    return {
      ...toRefs(state),
      getList,
      reset,
      pageRef,
      handleAdd,
      handleEdit,
      handleUpdateAuth,
      handleReset,
      handleDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.crc-ma-in-character {
  .cmic-form-row {
    .cmicfr-add-btn {
      border-radius: 16px;
      border-color: #8a88c3;
      background-color: #8a88c3;
    }
  }

  .el-table {
    height: calc(100% - 100px);
  }
}
</style>
